import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Privacy = () => {
    return (
        <section style={{ display: "block", padding: "50px", color: "#fff", background: "#000" }}>
          <Row style={{ paddingBottom: "35px" }}>
            <Link to="/" style={{color: "#fff"}}>
            &#8592; <span style={{textDecoration: "underline"}}>Volver</span>
            </Link>
          </Row>
          <Row>
            <h1>POLÍTICA DE PRIVACIDAD</h1>
          </Row>
          <Row>
            <h2>Política de privacidad y gestión de datos</h2>
            <p>
              Con objeto de dar cumplimiento a las obligaciones derivadas del
              Reglamento (UE) 2016/679 (RGPD) y la Ley Orgánica 3/2018 (LOPDGDD),
              GRUPO DANI GARCÍA le informa que al marcar este check, usted da su
              consentimiento para que sus datos personales quedan incorporados a
              los ficheros de datos de carácter personal de GRUPO DANI GARCÍA,
              para la prestación de servicios por parte de la misma y prospección
              comercial.
            </p>
            <p>
              El Responsable del mencionado fichero es GRUPO DANI GARCÍA con email
              <a href="mailto:dpd@grupodanigarcia.com" target="_blank" rel="noopener noreferrer" className="mail-link">
                &nbsp;dpd@grupodanigarcia.com&nbsp;
              </a>
              a la cual usted podrá remitir un comunicado identificado con la
              referencia “Protección de Datos” para el ejercicio de sus derechos
              de acceso, rectificación, cancelación, olvido, limitación,
              portabilidad y oposición.
            </p>
            <p>
              Empresas que forman el GRUPO DANI GARCIA (responsables de los
              ficheros):
            </p>
            <p>
              OXÍMORON GASTRONÓMICO S.L. (B93289890)<br></br>
              ALEGORÍA GASTRONÓMICA S.L.U. (B93448504)<br></br>
              ENIGMA GASTRONÓMICO S.L. (B93441897)<br></br>
              EUFORIA GASTRONÓMICA S.L. (B93488203)<br></br>
              IRONÍA GASTRONÓMICA S.L. (B93488211)<br></br>
              SIMIL GASTRONÓMICO S.L. (B93668598)<br></br>
              CONTRASTE GASTRONÓMICO S.L. (B93668580)<br></br>
              RIMA GASTRONOMICA SL (B93735702)
            </p>
            <div id="ot-sdk-cookie-policy"></div>
          </Row>
        </section>
    )
}

export default Privacy;