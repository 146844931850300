import { Col, Container, Image, Row } from "react-bootstrap";
import "./App.css";
import "./firebase.js";
function App() {
  return (
    <div className="App">
      <div className="App-header">
        <Row style={{ alignItems: "center",width: "100%"}}>
          <Col xs={12} style={{ marginBottom: 25 }}>
            <Image
              className="imagen"
              width={"500px"}
              src={
                "/assets/Rositas-rosa-y-negro.png"
              }
            ></Image>
          </Col>
          <Col xs={12} lg={6}>
            <Image
              className="imagen"
              width={"500px"}
              src={
                "/assets/Rositas 3 + packaging A.png"
              }
            ></Image>
          </Col>
          <Col xs={12} lg={6} style={{ marginTop: "35px" }}>
            <Row>
              <Col xs={12} style={{ fontWeight: "bold" }}>
                <p>Endulza el Día de San Valentín</p>
              </Col>
              <Col xs={6} style={{ marginTop: "15px" }}>
                <Row>
                  <Col xs={12}>
                    <Image
                      height={"40px"}
                      className="imagenAgregador"
                      src={
                        "/assets/Just Eat_black.png"
                      }
                    ></Image>
                  </Col>
                  <Col xs={12} style={{ marginTop: "35px" }}>
                    <a
                      href="https://www.just-eat.es/a-domicilio/cadenas/dani-garcia"
                      rel="noopener nooreferrer"
                      target="_blank"
                      className="enlace"
                    >
                      ¡Pruébalas ya!
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col xs={6} style={{ marginTop: "15px" }}>
                <Row>
                  <Col xs={12}>
                    <Image
                      height={"40px"}
                      className="imagenAgregador"
                      src={
                        "/assets/Glovo_black.png"
                      }
                    ></Image>
                  </Col>
                  <Col xs={12} style={{ marginTop: "35px" }}>
                    <a
                      href="/glovo"
                      className="enlace"
                      rel="noopener nooreferrer"
                      target="_blank"
                    >
                        ¡Pruébalas ya!
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} style={{ fontWeight: "400", marginTop: "25px", fontWeight:"bold" }}>
                <p> Una manera maravillosamente dulce de saborear la vida</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <section
      className="footer"
        style={{ backgroundColor: "#000", color: "#fff", paddingBottom:"15px",paddingTop:"15px" }}
      >
        <Row
          style={{ width: "100%", alignItems: "center", margin: 0, padding: 0 }}
        >
          <Col xs={2} style={{ textAlign: "center", margin: 0, padding: 0 }}>
            <Image
              className="logosFooter"
              src="/assets/logo copy.png"
            ></Image>
          </Col>
          <Col xs={8} style={{ textAlign: "center" }}>
            <a href="/politica-de-privacidad" style={{color: "#fff", textTransform: "none", textDecoration: "none"}}>Política de privacidad</a><br />
            @ 2022 GRUPO DANI GARCÍA
          </Col>
          <Col xs={2} style={{ textAlign: "center", margin: 0, padding: 0 }}>
            <Image
              className="logosFooter"
              src="/assets/Dani Garcia_1.png"
            ></Image>
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default App;
