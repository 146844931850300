import { Col, Container, Row, Image } from "react-bootstrap";
import "./glovo.css";

export default function Glovo() {
  return (
    <>
      <div className="fondoglovo">
        <Row className="justify-content-md-center logoglovo">
          <Col xs={12} md={12} className="d-flex justify-content-center w-100">
            <Image className="imgglovo" src="/assets/logo_green.svg" fluid />
          </Col>
        </Row>
        <Row style={{marginRight: '0px'}}>
          <Col xs={12} md={12} style={{fontSize: 25, fontFamily: "Poppins", fontWeight: "bold", textTransform: "uppercase", textAlign: "center"}}>
            Dime dónde vives ...
          </Col>
          <Col xs={12} md={12} style={{fontSize: 35, fontFamily: "Poppins", fontWeight: "bold", textTransform: "uppercase",textAlign: "center"}}>
            y te diré de qué cocina eres
          </Col>
        </Row>
        <Row className="fondocontent text-center justify-content-center" style={{marginRight: '0px'}}>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Madrid" href="https://glovoapp.com/es/es/madrid-sur/la-gran-familia-mediterranea-leganes-getafe/">
              <h2 className="letras">
                Ma<br></br>dr<br></br>id
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Barcelona" href="https://glovoapp.com/es/es/barcelona/la-gran-familia-mediterranea-barcelona/">
              <h2 className="letras">
                Bar<br></br>cel<br></br>ona
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Marbella" href="https://glovoapp.com/es/es/nueva-andalucia/la-gran-familia-mediterranea-marbella/">
              <h2 className="letras">
                Mar<br></br>be<br></br>lla
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Sevilla" href="https://glovoapp.com/es/es/sevilla/la-gran-familia-mediterranea-by-dani-garcia-sevilla/">
              <h2 className="letras">
                Se<br></br>vi<br></br>lla
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Valencia" href="https://glovoapp.com/es/es/valencia/la-gran-familia-mediterranea-val/">
              <h2 className="letras">
                Val<br></br>en<br></br>cia
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Cordoba" href="https://glovoapp.com/es/es/cordoba/la-gran-familia-mediterranea-cordoba/">
              <h2 className="letras">
                Cór<br></br>do<br></br>ba
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Granada" href="https://glovoapp.com/es/es/granada/la-gran-familia-mediterranea-granada/">
              <h2 className="letras">
                Gra<br></br>na<br></br>da
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Almeria" href="https://glovoapp.com/es/es/almeria/la-gran-familia-mediterranea-almeria/">
              <h2 className="letras">
                Al<br />me<br />ría
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Sabadell" href="https://glovoapp.com/es/es/sabadell/la-gran-familia-mediterranea-by-dani-garcia-sabadell/">
              <h2 className="letras">
                Sa<br />ba<br />dell
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Alicante" href="https://glovoapp.com/es/es/alicante/la-gran-familia-mediterranea-alicante/">
              <h2 className="letras">
                Ali<br />can<br />te
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Palma" href="https://glovoapp.com/es/es/palma/la-gran-familia-mediterranea-palma/">
              <h2 className="letras">
                Pal<br />ma
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Elche" href="https://glovoapp.com/es/en/elche/la-gran-familia-mediterranea-by-dani-garcia-elche/">
              <h2 className="letras">
                El<br />che
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Acoruna" href="https://glovoapp.com/es/es/a-coruna/la-gran-familia-mediterranea-a-coruna/">
              <h2 className="letras">
                A<br />co<br />ru<br />ña
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
          <Col md={2} xs={4} className="cajaglovo d-flex justify-content-center align-middle">
            <a className="bolaglovo d-flex justify-content-center align-middle text-center Glovo-Valladolid" href="https://glovoapp.com/es/es/valladolid/la-gran-familia-mediterranea-valladolid/">
              <h2 className="letras">
                Va<br />lla<br />do<br />lid
              </h2>
              <div className="lengua"></div>
            </a>
          </Col>
        </Row>
        <div className="d-flex align-items-center">
          <Col xs={12} className="textblanco" style={{textAlign: "center", fontSize: 15, color: 'black'}}>
            <span style={{color: 'black'}}>© 2022 Grupo Dani García</span>
          </Col>
        </div>
      </div>
    </>
  );
}
